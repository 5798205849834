module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P4RNF8D","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ilhamfadhil.com","short_name":"Ilham's Blog","start_url":"/blog","background_color":"#3daeff","theme_color":"#3daeff","display":"fullscreen","icon":"/opt/build/repo/static/images/IF.png","shortcuts":[{"name":"Read from Ilham's Blog","short_name":"Read Blog","description":"View latest blog posts from Ilham","url":"/blog","icons":[{"src":"/opt/build/repo/static/images/IF.png","sizes":"192x192"}]}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a197a2b340f9f078f6cfc486de06f0e2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"dontCacheBustURLsMatching":{},"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"}],"skipWaiting":true,"clientsClaim":true},"precachePages":["/blog/","/blog/*","/about-me/","/contact/","/post-categories/*"]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"white","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js","stylesPath":"/opt/build/repo/static/admin/admin.css"},
    },{
      plugin: require('../node_modules/gatsby-plugin-ackee-tracker/gatsby-browser.js'),
      options: {"plugins":[],"domainId":"c56e2185-6ca8-43e0-b205-5df91f0cf13e","server":"https://ackee-server-ilham.herokuapp.com/","ignoreLocalhost":true,"detailed":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
